<template>
  <div>
    <v-card class="ma-2" v-if="false">
      <v-row>
        <v-col cols="4">
          <v-card-title>Bus Name: Sundhara Travels</v-card-title>
          <v-card-text>
            <div>
              <v-card-subtitle class="font-weight-black">Bus Routes: </v-card-subtitle>
            </div>
          </v-card-text>
        </v-col>
        <v-col cols="8">
          <leaflet-map />
        </v-col>
      </v-row>
    </v-card>
    <div class="ma-2">
      <data-iterator :headers="payloadHeaders" :payload="payload"> 
        <template #extraDetails>
          <v-btn outlined color="primary" tile class="mx-2" @click="$router.push(`/transportion/routes`)"> Manage Routes </v-btn>
        </template>
      </data-iterator>
    </div>
    <v-dialog v-model="drawer" absolute bottom temporary right width="500px">
      <v-card elevation="0" height="100%">
        <v-card-title class="primary white--text px-4">
          <span class="title"> {{ modelObj._id ? 'Update Vehicle' : 'Add Vehicle'}} </span>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Number" v-model="modelObj.number" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Driver" v-model="modelObj.driver"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox outlined dense hide-details label="Is Active" v-model="modelObj.isactive"></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn color="success" class="mx-2" @click="saveUpdateHandler()">{{modelObj._id ? 'Update' : 'Save'}}</v-btn>
            <v-btn @click="drawer= false" color="error">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue'),
    'leaflet-map': () => import('@/components/LeafletMap.vue')
  },
  data () {
    return {
      drawer: false,
      routesDrawer: false,
      listOfForms: [],
      listOfUsers: [],
      modelObj: {},
      payload: {
        moduleIcon: 'mdi-bus',
        // showBackBtn: false,
        create: () => this.drawer = true,
        items: [],
        showSelect: false,
        actionsList: [
          {
            click: (item) => this.editHandler(item._id),
            icon: 'mdi-pencil',
            color: 'primary'
          },
          {
            click: (item) => this.deleteHandler(item._id),
            icon: 'mdi-delete',
            color: 'error'
          }
        ]
      }
    }
  },
  mounted () {
    this.getAllUsers()
    this.getListHandler()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Number',
        value: 'number'
      }, {
        text: 'Driver',
        value: 'driver'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getAllUsers () {
      this.$_execute('get', 'users').then(({ data }) => {
        this.listOfUsers = data
      })
    },
    getListHandler () {
      this.$_execute('get', 'vehicles').then(response => {
        this.payload.items = response.data
      })
    },
    saveUpdateHandler () {
      let url = this.modelObj._id ? `vehicles/${this.modelObj._id}` : `/vehicles`
      let method = this.modelObj._id ? `put` : `post`
      this.$_execute(method, url, this.modelObj).then(() => {
        this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        this.$refs.form.reset()
        this.modelObj = {}
        this.drawer = false
        this.getListHandler()
      })
    },
    editHandler (id) {
      this.$_execute('get', `vehicles/${id}`).then(response => {
        this.drawer = true
        let model = response.data[0]
        this.modelObj = model
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'vehicles',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
